import { language } from 'utils/client';

import en_US from 'translations/en_US';
import ru_RU from 'translations/ru_RU';


const LANGUAGE_MAP = {
    'default': {
        "name": "Russian",
        "messages": ru_RU
    },
    'ru-RU': {
        "name": "Russian",
        "messages": ru_RU
    },
    'en-US': {
        "name": "English",
        "messages": en_US
    }
};

LANGUAGE_MAP['en-GB'] = LANGUAGE_MAP['en-US'];
LANGUAGE_MAP['en-CA'] = LANGUAGE_MAP['en-US'];
LANGUAGE_MAP['en-AU'] = LANGUAGE_MAP['en-US'];
LANGUAGE_MAP['en-NZ'] = LANGUAGE_MAP['en-US'];

export const getMessage = id => {
    return (LANGUAGE_MAP[language] || LANGUAGE_MAP['default']).messages[id];
};
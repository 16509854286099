const MAP = {
    "VERIFY.TITLE": "Сервис проверки документов",
    "VERIFY.DESCRIPTION": "Чтобы проверить документ, введите его уникальный ID в поле ниже и нажмите \"Проверить\"",
    "VERIFY.INPUT_CODE": "XXXXXXXXXXX",
    "VERIFY.SUBMIT": "Проверить",
    "VERIFY.STATUS_OK": "✅ Этот документ действителен",
    "VERIFY.STATUS_OK_DESCRIPTION": "Документ считается действительным, если данные, указанные в нем, соответствуют следующим:",
    "VERIFY.STATUS_OK_TYPE": "Тип документа",
    "VERIFY.STATUS_OK_ASSIGNEE": "Получатель",
    "VERIFY.STATUS_OK_TIMESTAMP": "Дата выдачи",
    "VERIFY.STATUS_ERROR": "⚠️ Этот документ может быть недействителен",
    "VERIFY.STATUS_ERROR_DESCRIPTION": "Нам не удалось найти информацию об этом документе",
    "VERIFY.STATUS_ERROR_STEP_1": "Пожалуйста, проверьте правильность введенного идентификатора",
    "VERIFY.STATUS_ERROR_STEP_2": "Напишите на <a href=\"mailto:info@strcol.edu.kz\">info@strcol.edu.kz</a>, чтобы мы вручную проверили действительность документа",
    "VERIFY.TYPE_CL": "Подтверждение об обучении",
    "VERIFY.TYPE_UNKNOWN": "Другое"
};

export default MAP;
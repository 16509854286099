import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';

import 'assets/css/style.css';

import { injectClientConfig } from 'utils/client';
import { getMessage } from 'utils/translation';
import axios from 'axios';


const App = () => {
    const query = new URLSearchParams(window.location.search);

    const [code, setCode] = useState('');
    const [status, setStatus] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        injectClientConfig();
        if (query.get('code')) {
            setCode(query.get('code'));
            plainCheckCode(query.get('code'));
        }
    }, []);

    const plainCheckCode = code => {
        setLoading(true);
        axios.get('https://verify.strcol.edu.kz/api/public/verify', { params: { code: code }}).then(response => {
            setStatus(response.data);
            setLoading(false);
        });
    };

    const checkCode = () => {
        setLoading(true);
        axios.get('https://verify.strcol.edu.kz/api/public/verify', { params: { code: code }}).then(response => {
            setStatus(response.data);
            setLoading(false);
        });
    };

    return <div className="main-container">
        <img src="/images/logo-plain.svg" />
        <h1>{getMessage('VERIFY.TITLE')}</h1>
        <span>{getMessage('VERIFY.DESCRIPTION')}</span>
        <div className="flex-container">
            <input
                onKeyDown={event => (event.key === 'Enter' && !(loading || code.length !== 11)) && checkCode()}
                onChange={event => setCode(event.target.value)}
                value={code}
                disabled={loading}
                spellCheck={false}
                placeholder={getMessage('VERIFY.INPUT_CODE')} />
            <button disabled={loading || code.length !== 11} onClick={checkCode}>{getMessage('VERIFY.SUBMIT')}</button>
        </div>
        {status && (status.valid ? <>
            <h2>{getMessage('VERIFY.STATUS_OK')}</h2>
            <span>{getMessage('VERIFY.STATUS_OK_DESCRIPTION')}</span>
            <ul>
                <li>{getMessage('VERIFY.STATUS_OK_TYPE')}: <b>{status.type === 1 ? getMessage('VERIFY.TYPE_CL') : getMessage('VERIFY.TYPE_UNKNOWN')}</b></li>
                <li>{getMessage('VERIFY.STATUS_OK_ASSIGNEE')}: <b>{status.assignee}</b></li>
                <li>{getMessage('VERIFY.STATUS_OK_TIMESTAMP')}: <b>{new Date(status.timestamp + 6 * 60 * 60000).toLocaleDateString()}</b></li>
            </ul>
        </> : <>
            <h2>{getMessage('VERIFY.STATUS_ERROR')}</h2>
            <span>{getMessage('VERIFY.STATUS_ERROR_DESCRIPTION')}</span>
            <ul>
                <li>{getMessage('VERIFY.STATUS_ERROR_STEP_1')}</li>
                <li><span dangerouslySetInnerHTML={{__html: getMessage('VERIFY.STATUS_ERROR_STEP_2')}} /></li>
            </ul>
        </>)}
    </div>;
};

ReactDOM.createRoot(document.getElementById('root')).render(<App/>);
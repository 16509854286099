var __userAgent = navigator.userAgent || navigator.vendor || window.opera;
var __device = 'other';
var __browser = 'other';
if (/android/i.test(__userAgent))
    __device = window.matchMedia('(display-mode: standalone)').matches ? 'android-native' : 'android-web';
if (/iPad|iPhone|iPod/.test(__userAgent) && !window.MSStream)
    __device = window.matchMedia('(display-mode: standalone)').matches ? 'iOS-native' : 'iOS-web';
if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime))
    __browser = 'chrome';
if (/constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification)))
    __browser = 'safari';
if (typeof InstallTrigger !== 'undefined')
    __browser = 'firefox';

export const localhost = Boolean(window.location.hostname === 'localhost' || window.location.hostname === '[::1]' || window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
export const language = navigator.language || navigator.userLanguage;
export const theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
export const device = __device;
export const browser = __browser;

export const injectClientConfig = () => {
    document.documentElement.dataset.language = language;
    document.documentElement.dataset.theme = theme;
    document.documentElement.dataset.device = device;
    document.documentElement.dataset.browser = browser;
};
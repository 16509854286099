const MAP = {
    "VERIFY.TITLE": "Verify issued documents",
    "VERIFY.DESCRIPTION": "To verify a document, enter its unique ID in the field below and click \"Verify\"",
    "VERIFY.INPUT_CODE": "XXXXXXXXXXX",
    "VERIFY.SUBMIT": "Verify",
    "VERIFY.STATUS_OK": "✅ This document is valid",
    "VERIFY.STATUS_OK_DESCRIPTION": "This document shall be considered valid if its data corresponds to the following:",
    "VERIFY.STATUS_OK_TYPE": "Type",
    "VERIFY.STATUS_OK_ASSIGNEE": "Assignee",
    "VERIFY.STATUS_OK_TIMESTAMP": "Issue date",
    "VERIFY.STATUS_ERROR": "⚠️ This document might not be valid",
    "VERIFY.STATUS_ERROR_DESCRIPTION": "We could not find any information about this document",
    "VERIFY.STATUS_ERROR_STEP_1": "Please check the accuracy of the code provided and try again",
    "VERIFY.STATUS_ERROR_STEP_2": "Feel free to contact us at <a href=\"mailto:info@strcol.edu.kz\">info@strcol.edu.kz</a>, and we will manually verify the document's validity for you",
    "VERIFY.TYPE_CL": "Enrollment Confirmation Letter",
    "VERIFY.TYPE_UNKNOWN": "Unknown"
};

export default MAP;